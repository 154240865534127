.detail-island {
  gap: 2rem;
  color: black;
  padding-left: 5%;
  padding-top: 1%;
  padding-right: 5%;
  display: flex;
  justify-content: center;
  text-align: center;
  .detail-trip {
    justify-content: center;
    width: 200px;
    height: 180px;
    text-decoration: none;
    color: #144d68;
    .photo-overlay {
      width: 100%;
      height: 100%;
      .island-james-khai,
      .island-rok-haa,
      .island-surin,
      .island-phiphi-khai,
      .island-phiphi-bamboo,
      .island-raya-noi,
      .island-similan,
      .island-similan2d1n,
      .island-surin2d1n,
      .island-surin3d2n {
        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 10%;
        transition: all 0.5s ease;
        .text-overlay {
          transition: 0.5s ease;
          opacity: 0;
        }
        &:hover {
          opacity: 1;
          filter: brightness(0.9);
          transform: scale(1.2);
          .text-overlay {
            transition: 0.5s ease;
            opacity: 1;
            .trip {
              color: white;
              font-size: 20px;
              text-align: center;
              padding-top: 30%;
            }
          }
        }
      }
      .island-james-khai {
        background-image: url("../../public/Img/Daytrip/James_Bond_Islands/photo2.jpg");
      }
      .island-rok-haa {
        background-image: url("../../public/Img/Daytrip/Rok_Haa/photo1.jpg");
      }
      .island-surin {
        background-image: url("../../public/Img/Daytrip/Surin/photo1.jpg");
      }
      .island-phiphi-khai {
        background-image: url("../../public/Img/Daytrip/PhiPhi_Khai/photo3.jpg");
      }
      .island-phiphi-bamboo {
        background-image: url("../../public/Img/Daytrip/PhiPhi_Bamboo/photo1.jpg");
      }
      .island-raya-noi {
        background-image: url("../../public/Img/Daytrip/Raya_Noi/photo1.jpg");
      }
      .island-similan {
        background-image: url("../../public/Img/Daytrip/Similan/photo7.jpg");
      }
      .island-similan2d1n {
        background-image: url("../../public/Img/Overnight/Similan_2D1N/photo7.jpg");
      }
      .island-surin2d1n {
        background-image: url("../../public/Img/Overnight/Surin_2D1N/Overnight.png");
      }
      .island-surin3d2n {
        background-image: url("../../public/Img/Overnight/Surin_3D2N/photo2.jpg");
      }
      p {
      }
    }
  }
}
