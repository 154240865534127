.carousel-photo {
  margin-top: 4rem;
  background-color: #efefef;
  width: 450px;
  position: absolute;
  left: 55%;
  top: 650px;
  img {
    padding: 2%;
    width: 450px;
    height: auto;
  }
  .recommend {
    width: 100%;
    height: 100%;
    .recommend-trip {
      height: 200px;
      .detail-island {
        color: black;
        padding-left: 5%;
        padding-top: 1%;
        padding-right: 5%;
        display: flex;
        justify-content: center;
        text-align: center;
        .detail-trip {
          justify-content: center;
          width: 200px;
          height: 180px;
          text-decoration: none;
          color: #144d68;
          .photo-overlay {
            width: 100%;
            height: 100%;
            .island-rok-haa,
            .island-surin,
            .island-phiphi-khai,
            .island-phiphi-bamboo,
            .island-raya-noi,
            .island-similan,
            .island-similan2d1n,
            .island-surin2d1n,
            .island-surin3d2n {
              background-position: center;
              background-size: cover;
              width: 100%;
              height: 100%;
              border-radius: 10%;
              transition: all 0.5s ease;
            }
            .island-rok-haa {
              background-image: url("../../public/Img/Daytrip/Rok_Haa/photo1.jpg");
            }
            .island-surin {
              background-image: url("../../public/Img/Daytrip/Surin/photo1.jpg");
            }
            .island-phiphi-khai {
              background-image: url("../../public/Img/Daytrip/PhiPhi_Khai/photo3.jpg");
            }
            .island-phiphi-bamboo {
              background-image: url("../../public/Img/Daytrip/PhiPhi_Bamboo/photo1.jpg");
            }
            .island-raya-noi {
              background-image: url("../../public/Img/Daytrip/Raya_Noi/photo1.jpg");
            }
            .island-similan {
              background-image: url("../../public/Img/Daytrip/Similan/photo7.jpg");
            }
            .island-similan2d1n {
              background-image: url("../../public/Img/Overnight/Similan_2D1N/photo7.jpg");
            }
            .island-surin2d1n {
              background-image: url("../../public/Img/Overnight/Surin_2D1N/Overnight.png");
            }
            .island-surin3d2n {
              background-image: url("../../public/Img/Overnight/Surin_3D2N/photo2.jpg");
            }
            p {
            }
          }
        }
      }
    }
    h4 {
    }
  }
  .other-trip {
    margin: 0;
    float: left;
    overflow: hidden;
    width: 450px;
    height: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
    a {
      border-radius: 10px;
      display:flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 35px;
      color: white;
      text-decoration: none;
      background-color: #144d68;
      &:hover {
        background-color: #1d8fc4;
        transition: 300ms;
      }
    }
  }
}

.recommend-mobile-size {
  display: none;
}

@media screen and (max-width: 1100px) {
  .recommend {
    display: none;
  }
  .recommend-mobile-size {
    display: block;
    position: relative;
    margin: 2rem 0 0 3rem;
    .recommend-trip-mobile-size {
      display: flex;
      justify-content: center;
      height: 150px;
      .detail-island-mobile-size {
        padding-left: 5%;
        padding-top: 1%;
        padding-right: 5%;
        display: flex;
        justify-content: center;
        text-align: center;
        .detail-trip-mobile-size {
          width: 200px;
          height: 150px;
          text-decoration: none;
          color: #144d68;
          .photo-overlay-mobile-size {
            width: 100%;
            height: 100%;
            .island-rok-haa-mobile-size,
            .island-surin-mobile-size,
            .island-phiphi-khai-mobile-size,
            .island-phiphi-bamboo-mobile-size,
            .island-raya-noi-mobile-size,
            .island-similan-mobile-size,
            .island-similan2d1n-mobile-size,
            .island-surin2d1n-mobile-size,
            .island-surin3d2n-mobile-size {
              background-position: center;
              background-size: cover;
              width: 100%;
              height: 100%;
              border-radius: 10%;
              transition: all 0.5s ease;
            }
            .island-rok-haa-mobile-size {
              background-image: url("../../public/Img/Daytrip/Rok_Haa/photo1.jpg");
            }
            .island-surin-mobile-size {
              background-image: url("../../public/Img/Daytrip/Surin/photo1.jpg");
            }
            .island-phiphi-khai-mobile-size {
              background-image: url("../../public/Img/Daytrip/PhiPhi_Khai/photo3.jpg");
            }
            .island-phiphi-bamboo-mobile-size {
              background-image: url("../../public/Img/Daytrip/PhiPhi_Bamboo/photo1.jpg");
            }
            .island-raya-noi-mobile-size {
              background-image: url("../../public/Img/Daytrip/Raya_Noi/photo1.jpg");
            }
            .island-similan-mobile-size {
              background-image: url("../../public/Img/Daytrip/Similan/photo7.jpg");
            }
            .island-similan2d1n-mobile-size {
              background-image: url("../../public/Img/Overnight/Similan_2D1N/photo7.jpg");
            }
            .island-surin2d1n-mobile-size {
              background-image: url("../../public/Img/Overnight/Surin_2D1N/Overnight.png");
            }
            .island-surin3d2n-mobile-size {
              background-image: url("../../public/Img/Overnight/Surin_3D2N/photo2.jpg");
            }
            p {
            }
          }
        }
      }
    }
    h4 {
      text-align: center;
    }
    .other-trip {
      border-radius: 10px;
      text-align: center;
      width: 100px;
      height: 35px;
      color: white;
      text-decoration: none;
      background-color: #144d68;
      position: absolute;
      left: 45%;
      bottom: -45%;
    }
  }
}
