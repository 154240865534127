@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;300;400&display=swap");
* {
  font-family: "Kanit", sans-serif;
  margin: 0px;
  padding: 0px;
}

/* -----------------Navbar----------------------------- */
.Headnavbar {
  padding-left: 15%;
  padding-right: 15%;
}
.Headnavbar img {
  padding: 1rem;
}
.Offcanvas_menu {
  position: absolute;
  margin: -1000%;
}
.nav a {
  padding: 0.5rem 1rem;
  color: black;
  text-decoration: none;
  transition: color 0.15s ease-in-out background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.Select_navbar {
  color: white;
  background-color: #144d68;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.navbar-collapse {
  justify-content: space-around;
}
.basic-navbar-nav {
  color: white;
}
.me-auto4 {
  font-size: 20px;
}
.me-auto4 a {
  justify-content: space-around;
  color: white;
  text-decoration: none;
  margin-left: 5rem;
}
.me-auto4 a:focus {
  color: rgb(0, 169, 175);
}
.me-auto4 a:hover{
  transition: 300ms;
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
.Headnavbar .nav-link,
.Headnavbar {
  color: #144d68;
}
@media screen and (max-width: 600px) {
  .Headnavbar {
    padding-left: 0;
    padding-right: 0;
  }
  .Headnavbar img {
    width: 70%;
  }
}
@media screen and (max-width: 1000px) {
  .offcanvas.show {
    transform: none;
    width: 100%;
    background: #00000000;
  }
  .Headnavbar {
    padding-left: 1%;
    padding-right: 1%;
  }
  .Offcanvas_menu {
    position: relative;
    margin: 0;
  }
  .boxnav {
    width: 33.333%;
  }
  .boxnav img {
    width: 100%;
  }
  .Offcanvas_link h1 {
    text-align: center;
    padding-top: 50px;
    text-decoration: none;
    
  }
  .Offcanvas_link a {
    color: white;
    text-decoration: none;
  }
  .Link:hover {
    transition: 300ms;
  }
  .offcanvas-header,
  .offcanvas-body {
    background: #144d689f;
  }
  .Offcanvas_link a:focus {
    color: rgb(101, 215, 243);
  }
}
/* ----------------Home----------------------------------- */
.Home .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: end;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 2.5rem;
  margin-left: 15%;
  list-style: none;
}
.Home .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transition: opacity 0.6s ease;
}
.Home {
  justify-items: center;
}

.text_home {
  position: absolute;
  right: 20%;
  bottom: 50%;
  left: 20%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: white;
  text-align: start;
  line-height: 1%;
}
.text_home h1 {
  font-size: 67px;
  line-height: 99%;
}
.text_home h3 {
  font-size: 30px;
  line-height: 0%;
}

.NameIsland_home {
  position: absolute;
  right: 20%;
  bottom: 5%;
  left: 20%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: white;
  text-align: start;
}
.NameIsland_home h4 {
  font-size: 19px;
}
.NameIsland_home b {
  font-size: 17px;
}
.Home img {
  height: 39.375rem;
  width: 50rem;
}
.Line_detail {
  display: flex;
}
.Line_detail img {
  height: 10%;
}


@media screen and (min-width: 1800px) {
  .Home .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: end;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 4rem;
    margin-left: 15%;
    list-style: none;
  }
  .Home .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    transition: opacity 0.6s ease;
  }
  .Home {
    justify-items: center;
  }

  .text_home {
    position: absolute;
    right: 20%;
    bottom: 50%;
    left: 20%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: white;
    text-align: start;
    line-height: 1%;
  }
  .text_home h1 {
    font-size: 100px;
    line-height: 99%;
  }
  .text_home h3 {
    font-size: 33px;
    line-height: 0%;
  }

  .NameIsland_home {
    position: absolute;
    right: 20%;
    bottom: 5%;
    left: 20%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: white;
    text-align: start;
  }
  .NameIsland_home h4 {
    font-size: 35px;
  }
  .NameIsland_home b {
    font-size: 22px;
  }
  .Home img {
    height: 100%;
    width: 100%;
  }
  .Line_detail {
    display: flex;
  }
  .Line_detail img {
    height: 10%;
  }
}
@media screen and (max-width: 1470px) {
  .Line_detail .col-sm-7 {
    width: 100%;
  }

  .Line_detail .col-sm-6 img,
  .Line_detail .col-sm-5 img {
    width: 0;
    height: 0;
  }
}
@media screen and (max-width: 800px) {
  .text_home {
    position: absolute;
    right: 20%;
    bottom: 50%;
    left: 20%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: white;
    text-align: start;
    line-height: 1%;
  }
  .text_home h1 {
    font-size: 40px;
  }
  .text_home h3 {
    font-size: 20px;
  }
  .NameIsland_home h4 {
    font-size: 17px;
  }
  .NameIsland_home b {
    font-size: 15px;
  }
  .Line_detail .col-sm-6,
  .Line_detail .col-sm-7 {
    width: 100%;
  }
  .Line_detail .col-sm-6 img,
  .Line_detail .col-sm-5 img {
    width: 0;
    height: 0;
  }

  .Home .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-bottom: 1rem;
    list-style: none;
  }
}

/* ------------Product---------------------- */
.Product {
  margin-top: 20px;
  margin-bottom: 100px;
}
.Head_Product {
  justify-content: center;
  padding-top: 2%;
  color: #144d68;
}
.Head_Product b {
  font-size: 17px;
}
.product_topic {
  text-align: start;
}
.Head_Product h1 {
  font-size: 67px;
  line-height: 50px;
}
.Detail_Trip {
  justify-content: center;
}
.Box_Trip {
  margin: 0.5%;
}
.Box_Trip a {
  text-decoration: none;
}
.Daytrip {
  background-image: url("/public/Img/Daytrip.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 500px;
  justify-content: center;
  padding-top: 380px;
  color: #144d68;
  margin-bottom: 5%;
}

.Overnight {
  background-image: url("/public/Img/Overnight.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 500px;
  padding-top: 380px;
  color: #144d68;
  margin-bottom: 5%;
}
.Icon_Daytrip {
  font-size: 20px;
  text-align: center;
  padding-top: 7%;
  background-color: white;
  border: 1px solid black;
  height: 160px;
  width: 90%;
  margin-left: 5%;
}

.Icon_Overnight {
  font-size: 20px;
  text-align: center;
  padding-top: 7%;
  background-color: white;
  border: 1px solid black;
  height: 160px;
  width: 90%;
  margin-left: 5%;
}
.Daytrip:hover .Icon_Daytrip,
.Overnight:hover .Icon_Overnight {
  padding-top: 4%;
  transition: 300ms;
}
.boxline {
  margin-left: 10%;
}
.Product .line {
  width: 100%;
  height: 5px;
}

@media screen and (max-width: 900px) {
  .Head_Product h1 {
    font-size: 40px;
  }
  .Product .line {
    width: 100%;
    height: 5px;
  }
  .boxline {
    margin-left: 0%;
  }
}
@media screen and (max-width: 600px) {
  .Head_Product h1 {
    font-size: 35px;
  }
  .Product .line {
    width: 100%;
    height: 5px;
    margin-top: -10px;
  }
  .boxline {
    margin-left: 0%;
  }
  .Product .col-sm-4 {
    width: 80%;
  }
  .Daytrip {
    margin-bottom: 100px;
  }

  .Overnight {
    margin-bottom: 15%;
  }
  .Head_Product {
    justify-content: start;
    padding-top: 2%;
    color: #144d68;
  }
  .Product .col-sm-6 {
    padding: 1%;
    width: 50%;
  }
  .Product .col-sm-3 {
    width: 50%;
    text-align: start;
  }
}
@media screen and (min-width: 1900px) {
  .Product {
    margin-top: 20px;
    margin-bottom: 100px;
  }
  .Daytrip {
    background-image: url("/public/Img/Daytrip.png");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 587px;
    justify-content: center;
    padding-top: 490px;
    color: #144d68;
  }

  .Overnight {
    background-image: url("/public/Img/Overnight.png");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 587px;
    padding-top: 490px;
    color: #144d68;
  }

  .Icon_Daytrip {
    font-size: 20px;
    text-align: center;
    padding-top: 7%;
    background-color: white;
    border: 1px solid black;
    height: 160px;
    width: 90%;
    margin-left: 5%;
  }

  .Icon_Overnight {
    font-size: 20px;
    text-align: center;
    padding-top: 7%;
    background-color: white;
    border: 1px solid black;
    height: 160px;
    width: 90%;
    margin-left: 5%;
  }
}

/* ------------About us---------------------- */
.About {
  padding-left: 10%;
  padding-right: 10%;
  justify-items: center;
  background-image: url("/public/Img/background.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  color: #144d68;
  align-items: center;
  text-align: start;
}
.Figure_img {
  width: 533;
  height: 123;
}
.Figure_img1 {
  position: absolute;
  margin: -1000%;
}
.boxabout {
  background-color: white;
  align-items: center;
  padding-right: 10%;
  padding-left: 10%;
}
.textabout h1 {
  font-size: 67px;
  text-align: start;
}
.textabout h3 {
  font-size: 25px;
  text-align: start;
}
.textabout p {
  font-size: 20px;
  text-align: start;
}
.textabout b {
  color: #144d68;
  font-size: 20px;
}
.wordblock{
  display:block;
  word-wrap:break-word;
}

.btnReadmore {
  color: #144d68;
  background-color: white;
}
.About .row {
  align-items: center;
}
@media screen and (min-width: 1700px) {
  .About {
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;
    justify-items: center;
    background-image: url("/public/Img/background.png");
    background-position: center;
    background-size: cover;
    width: 100%;
    color: #144d68;
    align-items: center;
    text-align: start;
  }
  .Figure_img {
    width: 5000px;
    height: 100%;
  }

  .textabout h1 {
    font-size: 50;
    padding-top: 5%;
    text-align: start;
  }
  .textabout h3 {
    font-size: 35px;
    text-align: start;
  }
  .textabout p {
    font-size: 20px;
    text-align: start;
  }
  .textabout b {
    color: #144d68;
    font-size: 20px;
  }
}
@media screen and (max-width: 1100px) {
  .textabout h1 {
    font-size: 40px;
  }
  .textabout h3 {
    font-size: 25px;
  }
  .textabout p {
    font-size: 18px;
  }
}
@media screen and (max-width: 800px) {
  .home-image{
    width: 100%;
    /* max-width:100vw; */
    height: auto;
    object-fit: cover;
    max-height: 100vh;
  }
  .About {
    margin-bottom: 0;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .About .line {
    height: 0;
    height: 5px;
    padding: 1%;
  }
  .textabout h1 {
    font-size: 50px;
    text-align: center;
  }
  .textabout h3 {
    font-size: 30px;
    text-align: center;
  }
  .textabout p {
    font-size: 16px;
    text-align: start;
  }
  .textabout b {
    color: #144d68;
    font-size: 20px;
  }
  .Figure_img {
    position: absolute;
    margin: -1000%;
  }
  .Figure_img1 {
    position: relative;
    margin: 0%;
    width: 20rem;
    height: auto;
  }
  .boxabout .col-md-6 {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 50px;
  }
}
/* ----------------Contact----------------------------------- */
.Contact {
  color: #144d68;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 150px;
}
.Contact * {
  justify-content: space-around;
}
.Contact .row {
  align-items: center;
  width: 100%;
}
.Contact h4 {
  font-size: 1rem;
}
.title_Contact {
  text-align: end;
  margin-right: 5rem;
}

.title_Contact h1 {
  font-size: 67px;
}

.btnContact {
  color: aliceblue;
  background-color: #144d68;
  border: 5px solid #144d68;
  border-radius: 5px;
}
.Contact .btn {
  display: block;
}
.btn p {
  text-align: center;
}
.Contact input,.Contact textarea{
  border: none;
    border-bottom: 1px solid;
    border-radius: 0px 0px 0px 0px;
}
@media screen and (max-width: 1600px) {
  .title_Contact h1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 600px) {
  .title_Contact h1 {
    font-size: 45px;
  }
  .title_Contact {
    text-align: end;
    margin-right: 0rem;
  }
  .Contact .col-sm-6 {
    width: 80%;
  }
  .Contact .btn span {
    text-align: center;
  }
  .Contact .row {
    align-items: center;
    width: fit-content;
  }
}
/* ----------------Footer----------------------------------- */
.boxfooter {
  justify-content: start;
}
.list-detail-contact, .list-detail-address, .list-detail-bulletin{
  margin-top: 2rem !important;
  height: 100%;
}
.boxfooter h5 {
  margin-top: 0;
  text-align: start;
}
.boxfooter li {
  text-align: start;
  font-size: 16px;
}
.linkpage {
  display: flex;
  justify-content: start;
}

@media screen and (min-width: 900px) {
  .footer * {
    justify-content: space-around;
  }
  .footer .row {
    align-items: start;
    width: 100%;
  }
  .boxfooter {
    justify-content: start;
    /* padding-left: 5%; */
  }
  .boxfooter h5 {
    text-align: start;
    font-size: 18px;
  }
  .boxfooter li {
    text-align: start;
    font-size: 16px;
  }
  .linkpage {
    display: flex;
    justify-content: start;
  }
}
@media screen and (max-width: 800px) {
  .footer * {
    justify-content: space-around;
  }
  .footer .row {
    align-items: center;
    width: 100%;
  }
  .boxfooter {
    justify-content: start;
    padding-left: 5%;
  }
  .boxfooter h5 {
    text-align: start;
    font-size: 18px;
  }
  .boxfooter li {
    text-align: start;
    font-size: 16px;
  }
  .linkpage {
    display: flex;
    justify-content: start;
  }
  .boxfooter .col-sm-3 {
    width: 50%;
  }
  .boxfooter h5 {
    text-align: start;
    font-size: 16px;
  }
  .boxfooter li {
    text-align: start;
    font-size: 11px;
  }
  .footer .figure-img{
    margin-bottom: -50px;
  }
  .list-detail-address, .list-detail-bulletin{
    display:none;
  }
  .list-detail-contact {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 370px){
  .boxfooter h5 {
    text-align: start;
    font-size: 15px;
  }
  .boxfooter li {
    text-align: start;
    font-size: 9px;
  }
  .footer .figure-img{
    margin-bottom: -50px;
    height: 100px;
    width: auto;
  }
}
/* ----------------Island----------------------------------- */
.Island {
  justify-content: end;
  text-align: start;
  color: white;
  margin-bottom: 100px;
}
.Island h2 {
  color: #144d68;
  padding: 1%;
  padding-left: 20%;
}
.text_overlay {
  transition: 0.5s ease;
  opacity: 0;
}

.Detail_Island {
  color: black;
  padding-left: 5%;
  padding-top: 1%;
  padding-right: 5%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.Detail_Island1 {
  padding-top: 1%;
  justify-items: center;
  margin-right: 5rem;
  margin-left: 5rem;
}
.slick-dots li button:before{
  font-size: 15px !important;
  line-height: 20px;
}
.Detail_Island1 .detail_trip a {
  color: #144d68;
  text-decoration: none;
}
.Detail_Island1 .detail_trip {
  margin: 5%;
  text-align: -webkit-center;
}
.home-image{
  width:100vw !important;
}
.box_tripp {
  align-items: center;
}
.detail_trip {
  width: 150px;
  height: 250px;
  text-align: -webkit-center;
  color: #144d68;
  text-decoration: none;
}
.detail_trip_overnight {
  width: 150px;
  height: 250px;
  text-align: -webkit-center;
  color: #144d68;
  text-decoration: none;
}
.trip {
  color: white;
  font-size: 20px;
  text-align: center;
  padding-top: 30%;
}
.Island .row {
  align-items: center;
}
.photo_overlay {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .Overnig_trip .Detail_Island {
    color: black;
    padding: 0;
    display: block;
    text-align: -webkit-center;
  }
}
@media screen and (max-width: 700px) {
  .Island {
    margin-bottom: 200px;
  }
  .Island .col-sm-3,
  .Island .col-sm-5 {
    width: 100%;
  }
  .Island .Head_Island1 h1,
  .Island .Head_Island2 h1,
  .Island .Head_Island3 h1,
  .Island .Head_Island4 h1,
  .Island .Head_Island5 h1,
  .Island .Head_Island6 h1,
  .Island .Head_Island7 h1,
  .Island .Head_Island8 h1,
  .Island .Head_Island9 h1,
  .Island .Head_Island10 h1,
  .Island .Head_Island11 h1,
  .Island .Head_Island12 h1,
  .Island .Head_Island13 h1 {
    font-size: 40px;
    text-align: center;
  }
  .Island h2 {
    text-align: center;
    color: #144d68;
    padding: 2%;
    padding-left: 0%;
    font-size: 20px;
    margin-top: 35px;
  }
  .Island .Detail_Island {
    display: grid;
  }
}
/* ----------------Island1----------------------------------- */
.Head_Island1 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/James_Bond_Islands/photo1.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

/* ----------------Island2----------------------------------- */
.Head_Island2 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/PhiPhi_Khai/photo5.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

.Island_Overnight2 {
  background-image: url("/public/Img/Daytrip/PhiPhi_Bamboo/photo2.jpg");
  background-position: center;
  background-size: cover;
  width: 150px;
  height: 150px;
}

/* ----------------Island3----------------------------------- */
.Head_Island3 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/PhiPhi_Bamboo/photo1.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

/* ----------------Island4----------------------------------- */
.Head_Island4 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/PhiPhi_Bamboo/photo3.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

/* ----------------Island5----------------------------------- */
.Head_Island5 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/PhiPhi_Khai/photo3.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

/* ----------------Island6----------------------------------- */
.Head_Island6 {
  padding: 4%;
  justify-content: center;
  /* -----ไม่เจอรูป------ */
  background-image: url("/public/Img/Daytrip/PhiPhi_Bamboo/photo5.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

/* ----------------Island7----------------------------------- */
.Head_Island7 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/Raya_Noi/photo3.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

/* ----------------Island8----------------------------------- */
.Head_Island8 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/Raya_Noi/photo5.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

/* ----------------Island9----------------------------------- */
.Head_Island9 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/Raya_Noi/photo2.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

/* ----------------Island10----------------------------------- */
.Head_Island10 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/Rok_Haa/photo3.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

/* ----------------Island11----------------------------------- */
.Head_Island11 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/Similan/photo7.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

/* ----------------Island12----------------------------------- */
.Head_Island12 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/Rok_Haa/photo1.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

/* ----------------Island13----------------------------------- */
.Head_Island13 {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/Surin/photo1.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  transition: 0.5s ease;
}

.Island_Overnight1 {
  background-image: url("/public/Img/Daytrip/James_Bond_Islands/photo2.jpg");
  background-position: center;
  background-size: cover;
  width: 150px;
  height: 150px;
}
/* ----------------DAYTRIP------------------------------------ */
.Island_James_Khai {
  background-image: url("/public/Img/Daytrip/James_Bond_Islands/photo2.jpg");
  background-position: center;
  background-size: cover;
  width: 95%;
  height: 100%;
  border-radius: 10%;
  transition: all 0.5s ease;
}
.Island_PhiPhi_Bamboo {
  background-image: url("/public/Img/Daytrip/PhiPhi_Bamboo/photo1.jpg");
  background-position: center;
  background-size: cover;
  width: 95%;
  height: 100%;
  border-radius: 10%;
  transition: all 0.5s ease;
}
.Island_PhiPhi_Khai {
  background-image: url("/public/Img/Daytrip/PhiPhi_Khai/photo3.jpg");
  background-position: center;
  background-size: cover;
  width: 95%;
  height: 100%;
  border-radius: 10%;
  transition: all 0.5s ease;
}
.Island_Raya_noi {
  background-image: url("/public/Img/Daytrip/Raya_Noi/photo1.jpg");
  background-position: center;
  background-size: cover;
  width: 95%;
  height: 100%;
  border-radius: 10%;
  transition: all 0.5s ease;
}
.Island_Rok_Haa {
  background-image: url("/public/Img/Daytrip/Rok_Haa/photo1.jpg");
  background-position: center;
  background-size: cover;
  width: 95%;
  height: 100%;
  border-radius: 10%;
  transition: all 0.5s ease;
}
.Island_Similan {
  background-image: url("/public/Img/Daytrip/Similan/photo7.jpg");
  background-position: center;
  background-size: cover;
  width: 95%;
  height: 100%;
  border-radius: 10%;
  transition: all 0.5s ease;
}
.Island_Surin {
  background-image: url("/public/Img/Daytrip/Surin/photo1.jpg");
  background-position: center;
  background-size: cover;
  width: 95%;
  height: 100%;
  border-radius: 10%;
  transition: all 0.5s ease;
}
/* ----------------OVERNIGHT------------------------------------ */
.Island_Similan2D1N {
  background-image: url("/public/Img/Overnight/Similan_2D1N/photo7.jpg");
  background-position: center;
  background-size: cover;
  width: 80%;
  height: 100%;
  border-radius: 10%;
  transition: all 0.5s ease;
}
.Island_Surin2D1N {
  background-image: url("/public/Img/Overnight/Surin_2D1N/Overnight.png");
  background-position: center;
  background-size: cover;
  width: 80%;
  height: 100%;
  border-radius: 10%;
  transition: all 0.5s ease;
}
.Island_Surin3D2N {
  background-image: url("/public/Img/Overnight/Surin_3D2N/photo2.jpg");
  background-position: center;
  background-size: cover;
  width: 80%;
  height: 100%;
  border-radius: 10%;
  transition: all 0.5s ease;
}
/* ----------------Island1 to Island13------------------------------------ */
.Head_Island1 h1,
.Head_Island2 h1,
.Head_Island3 h1,
.Head_Island4 h1,
.Head_Island5 h1,
.Head_Island6 h1,
.Head_Island7 h1,
.Head_Island8 h1,
.Head_Island9 h1,
.Head_Island10 h1,
.Head_Island11 h1,
.Head_Island12 h1,
.Head_Island13 h1 {
  font-size: 60px;
}

.Head_Island1:hover,
.Head_Island2:hover,
.Head_Island3:hover,
.Head_Island4:hover,
.Head_Island5:hover,
.Head_Island6:hover,
.Head_Island7:hover,
.Head_Island8:hover,
.Head_Island9:hover,
.Head_Island10:hover,
.Head_Island11:hover,
.Head_Island12:hover,
.Head_Island13:hover {
  opacity: 1;
  filter: brightness(0.85);
}
.Head_Island1:hover .text_overlay,
.Head_Island2:hover .text_overlay,
.Head_Island3:hover .text_overlay,
.Head_Island4:hover .text_overlay,
.Head_Island5:hover .text_overlay,
.Head_Island6:hover .text_overlay,
.Head_Island7:hover .text_overlay,
.Head_Island8:hover .text_overlay,
.Head_Island9:hover .text_overlay,
.Head_Island10:hover .text_overlay,
.Head_Island11:hover .text_overlay,
.Head_Island12:hover .text_overlay,
.Head_Island13:hover .text_overlay {
  opacity: 1;
  text-shadow: 1px 1px 1px black;
}
.Daytrip:hover .text_overlay {
  opacity: 1;
}
.Island_James_Khai:hover,
.Island_PhiPhi_Bamboo:hover,
.Island_PhiPhi_Khai:hover,
.Island_Raya_noi:hover,
.Island_Rok_Haa:hover,
.Island_Similan:hover,
.Island_Surin:hover,
.Island_Similan2D1N:hover,
.Island_Surin2D1N:hover,
.Island_Surin3D2N:hover {
  opacity: 1;
  filter: brightness(0.9);
  transform: scale(1.2);
}
.Island_James_Khai:hover .text_overlay,
.Island_PhiPhi_Bamboo:hover .text_overlay,
.Island_PhiPhi_Khai:hover .text_overlay,
.Island_Raya_noi:hover .text_overlay,
.Island_Rok_Haa:hover .text_overlay,
.Island_Similan:hover .text_overlay,
.Island_Surin:hover .text_overlay,
.Island_Similan2D1N:hover .text_overlay,
.Island_Surin2D1N:hover .text_overlay,
.Island_Surin3D2N:hover .text_overlay {
  opacity: 1;
  text-shadow: 1px 1px 1px black;
}

.Daytrip:hover,
.Overnight:hover {
  opacity: 1;
  filter: brightness(0.9);
}

.Daytrip:hover .text_overlay,
.Overnight:hover .text_overlay {
  opacity: 1;
}

.Daytrip_carousels {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin: top 64px;
}
@media screen and (max-width: 1100px) {
  .Daytrip_carousels {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin: top 64px;
  }
}
@media screen and (max-width: 600px) {
  .Daytrip_carousels {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin: top 64px;
  }
}
/* ----------------Accordion Trip----------------------------------- */
.Accordion_Selecttrip {
  margin-bottom: 16%;
}
@media screen and (max-height: 919px) {
  .Accordion_Selecttrip {
    margin-bottom: 460px;
  }
}
.Accordion_Trip {
  justify-content: end;
  text-align: start;
  color: white;
}
.Accordion_Daytrip {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Daytrip/Similan/photo2.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 18rem;
  align-items: center;
  padding-left: 45%;
}

.Select_Accordion_Trip .Detail_Island1 {
  margin-top: 150px;
  margin-bottom: 80px;
}
.Overnig_trip .Detail_Island1 {
  margin-bottom: 80px;
}
.Accordion_Daytrip h1 {
  font-size: 67px;
  color: white;
}
.Accordion_Overnight {
  padding: 4%;
  justify-content: center;
  background-image: url("/public/Img/Overnight/Surin_3D2N/photo1.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 18rem;
  align-items: center;
  padding-right: 20%;
}
.Accordion_Overnight h1 {
  font-size: 67px;
  color: white;
}

.detail_trip1 {
  width: 150px;
  height: 250px;
  text-align: -webkit-center;
  color: #144d68;
  text-decoration: none;
}
.BoxDaytrip {
  color: white;
  opacity:1;
  padding-top: 2%;
  text-align: end;
  border: 1px solid gainsboro;
  background-image: url("/public/Img/Daytrip/Similan/photo2.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
}
.Button_Daytrip h2 {
  margin-right: 20%;
}
.BoxDaytrip .text_overlay p {
  margin-right: 20%;
}
.Button_Daytrip {
  text-align: end;
  text-decoration: none;
  transition: 300ms;
}
.BoxDaytrip:hover,
.Button_Daytrip:hover {
  opacity: 1;
  color: white;
  background-image: url("/public/Img/Daytrip/Similan/photo2.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
  transition-duration: 300ms;
}
.BoxOvernight p,
.BoxDaytrip p {
  line-height: 0.1;
  padding-left: 0px;
  font-size: 0px;
}
.BoxOvernight {
  background-image: url("/public/Img/Overnight/Surin_3D2N/photo1.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  padding-top: 2%;
  text-align: start;
  border: 1px solid gainsboro;
}
.Button_Overnight h2 {
  padding-left: 20%;
}
.BoxOvernight:hover p,
.BoxDaytrip:hover p {
  padding-left: 20%;
  font-size: 18px;
  transition: 300ms;
}
.Button_Overnight {
  text-align: start;
  color: white;
  text-decoration: none;
}
.BoxOvernight:hover .text_overlay,
.BoxDaytrip:hover .text_overlay {
  opacity: 1;
  text-shadow: 1px 1px 1px black;
}
.BoxOvernight:hover img,
.BoxDaytrip:hover img {
  opacity: 1;
  filter: brightness(100);
}
.BoxOvernight:hover,
.Button_Overnight:hover {
  opacity: 1;
  background-image: url("/public/Img/Overnight/Surin_3D2N/photo1.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  transition: 300ms;
}

@media screen and (max-width: 760px) {
  .Select_Accordion_Trip .Detail_Island1 {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
  }
  .Overnig_trip .Detail_Island1 {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
  }
}
@media screen and (max-width: 600px) {
  .Accordion_Overnight h1,
  .Accordion_Daytrip h1 {
    font-size: 50px;
    color: white;
  }
  .Accordion_Daytrip {
    text-align: center;
    padding-left: 0%;
  }

  .Accordion_Overnight {
    text-align: center;
    padding-right: 0%;
  }
}
/* ----------------Islands----------------------------------- */
.Islands .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 0.5rem;
  margin-left: 15%;
  list-style: none;
}
.Islands .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transition: opacity 0.6s ease;
}
.Islands {
  margin-top: 20px;
  margin-bottom: 100px;
}
.Islands .line img {
  width: 110%;
  height: 5px;
  margin-left: 10%;
  margin-top: 10px;
}
.Islands .line {
  padding-left: 10%;
  height: 5px;
}
.Islands h1 {
  padding-right: 15%;
  text-align: end;
}
.Islands .textt {
  text-align: center;
  font-size: 20px;
  padding-right: 5%;
}
.Head_Islands {
  justify-content: center;
  padding-top: 2%;
  color: #144d68;
}

.Head_Islands h1 {
  font-size: 67px;
  line-height: 50px;
}
.Head_Islands b {
  text-align: end;
}

.Islands .CarouselIMG,
.Islands .island {
  padding-left: 20%;
  padding-right: 20%;
  list-style: none;
}

.Islands .CarouselIMG {
  display: flex;
  height: 587px;
}

.Islands .island {
  flex: 1;
  transition: flex 100ms ease-out;
  padding: 20px;
  height: 36.688rem;
}
.btnSeemore {
  background-color: #144d68;
  border-radius: 10px;
}

.Islands .island b {
  font-size: 0px;
  transition: 0.5s ease;
}
.Islands .island:hover {
  flex: 0 1 auto;
  filter: contrast(0.7);
}
.detailtrip_overlay {
  color: white;
  padding: 10%;
  padding-top: 20%;
  text-align: start;
  transition: 0.5s ease;
  opacity: 0;
  text-shadow: 1px 1px 1px black;
}
.Islands .island:hover .detailtrip_overlay {
  opacity: 1;
}
.Islands .island:hover b {
  opacity: 1;
  font-size: 16px;
  text-align: start;
}
.Islands .Carousel_trip2 {
  position: absolute;
  margin: -1000%;
  width: 0;
  height: 0;
}
@media screen and (max-width: 1300px) {
  .Islands .Carousel_trip1 {
    position: absolute;
    margin: -1000%;
    width: 0;
    height: 0;
  }
  .Islands .Carousel_trip2 {
    position: relative;
    margin: 0%;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 600px) {
  .Islands .CarouselIMG,
  .Islands .island {
    padding-left: 0%;
    padding-right: 0%;
    list-style: none;
  }
  .Islands h1 {
    font-size: 40px;
    padding-right: 0;
  }
  .Islands .col-sm-8 {
    width: 50%;
  }
  .Islands .col-sm-3 {
    width: 50%;
  }
  .Islands .line {
    margin-left: 0%;
    height: 5px;
  }
  .Islands .line img {
    width: 200%;
    height: 5px;
    margin-left: -25%;
    margin-bottom: -10px;
  }

  .Islands .CarouselIMG {
    display: flex;
    height: auto;
  }

  .Islands .island {
    flex: 1;
    transition: flex 100ms ease-out;
    padding: 0;
    height: 30rem;
  }
  .btnSeemore {
    background-color: #144d68;
    border-radius: 10px;
  }

  .Islands .island b {
    font-size: 0px;
    transition: 0.5s ease;
  }
  .Islands .textt {
    padding-left: 0%;
    font-size: 20px;
    text-align: end;
    padding-right: 0%;
  }
  .Islands .textt b {
    padding-left: 0%;
    font-size: 20px;
    background-color: white;
  }
  .Islands .island:hover {
    flex: 0 1 auto;
    filter: contrast(0.7);
    line-height: 1;
  }
  .detailtrip_overlay {
    color: white;
    padding: 10%;
    padding-top: 10%;
    text-align: start;
    transition: 0.5s ease;
    opacity: 0;
    text-shadow: 1px 1px 1px black;
  }
  .Islands .island:hover .detailtrip_overlay {
    opacity: 1;
  }
  .Islands .island:hover b {
    opacity: 1;
    font-size: 12px;
    text-align: start;
  }
  .Islands .Carousel_trip1 {
    position: absolute;
    margin: -1000%;
    width: 0;
    height: 0;
  }
  .Islands .Carousel_trip2 {
    position: relative;
    margin: 0%;
    width: 100%;
    height: 100%;
  }
  .Carousel_trip2 .carousel-control-prev {
    display: none;
  }
  .Carousel_trip2 .carousel-control-next {
    display: none;
  }
}
/* -----------------Deteail_Daytrip----------- */
.Detail_Daytrip {
  justify-content: center;
  color: #144d68;
  padding-bottom: 10%;
}
.Headname_Island {
  font-size: 20px;
  color: white;
  background-color: #144d68;
  text-align: start;
  margin-top: 4%;
  margin-left: 20%;
  margin-right: 20%;
  padding: 2%;
}
.Headname_Island h1 {
  font-size: 30px;
}
.Headname_Island1 {
  font-size: 20px;
  color: white;
  background-color: #144d68;
  text-align: start;
  margin-top: 1%;
  margin-left: 20%;
  margin-right: 20%;
  padding: 2%;
}
.Headname_Island1 h1 {
  font-size: 30px;
}
.detail_trips {
  background-color: #efefef;
  justify-content: start;
  margin-left: 15%;
  margin-right: 15%;
}
.timeline {
  margin-left: -75%;
}
.Detailname_Island {
  width: 100%;
  max-width: 845px;
  font-size: 18px;
  color: white;
  background-color: #144d68;
  text-align: start;
  margin-left: 20%;
  margin-right: 20%;
  padding: 1%;
}
.Detailname_Island h1 {
  font-size: 18px;
  color: white;
  text-align: start;
}

.Listtext li {
  display: flex;
}
.Listtext p {
  margin: 2%;
}

.btnDay1 b,
.btnDay2 b,
.btnDay3 b {
  font-size: 16px;
  color: #144d68;
}
.Name_Overnight_trip h1 {
  padding-left: 40px;
}
.btnDay1 {
  width: 80px;
  border: 5px solid black;
  border-radius: 10px;
}
.btnDay2 {
  width: 80px;
  border: 5px solid black;
  border-radius: 10px;
}
.btnDay3 {
  width: 80px;
  border: 5px solid black;
  border-radius: 10px;
}
.Detail_Daytrip li img {
  width: auto;
  height: 20px;
}
.carousel-photo {
  width: 450px;
  position: absolute;
  left: 55%;
  top: 650px;
}
.carousel-photo img {
  padding: 2%;
  width: 450px;
  height: auto;
}
.hover {
  padding: 2%;
  width: 450px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.hover img {
  width: 100%;
  transition: 0.5s all ease-in-out;
}
.hover:hover img {
  transform: scale(1.5);
}
.carousel_photo1 {
  width: 450px;
  position: absolute;
  left: 55%;
  top: 750px;
}
.RECOMMEND1 {
  position: absolute;
  margin: -1000%;
}
.recommend-header{
  color: white;
}
@media screen and (max-width: 1100px) {
  .Detail_Daytrip .icon_part2{
    margin-top: -40px;
  }
  .carousel_photo {
    text-align: center;
    width: auto;
    position: static;
  }
  .hover {
    padding: 2%;
    width: 300px;
    height: 200px;
  }
  .carousel_photo1 {
    text-align: center;
    width: auto;
    position: static;
  }
  .detail_trips .col-sm-6 {
    width: 100%;
  }
  .Detailname_Island .col-sm-6 {
    width: 100%;
  }
  .Detail_Daytrip li img {
    width: 20px;
    height: 20px;
  }
  .carousel_photo1 {
    padding-left: 15%;
    padding-right: 15%;
  }
  .carousel_photo {
    padding-left: 15%;
    padding-right: 15%;
  }
  .RECOMMEND {
    position: absolute;
    margin: -1000%;
  }
  .RECOMMEND1 {
    position: relative;
    margin: 0%;
    padding-left: 20%;
    padding-right: 20%;
  }
}
@media screen and (max-width: 600px) {
  .Detail_Daytrip {
    justify-content: center;
    color: #144d68;
    padding-bottom: 10%;
  }
  .Headname_Island {
    font-size: 20px;
    color: white;
    background-color: #144d68;
    text-align: start;
    margin-top: 4%;
    margin-left: 20%;
    margin-right: 0%;
    padding: 2%;
  }
  .Headname_Island h1 {
    font-size: 30px;
  }
  .Headname_Island1 h1 {
    font-size: 30px;
  }
  .Detail_Daytrip .detail_trips {
    margin-left: 0%;
    margin-right: 0%;
  }
  .slick-prev {
    left: -65px !important;
    z-index: 1;
  }
  .slick-next {
    right: -65px !important;
    z-index: 1;
  }
  .timeline {
    margin-left: -75%;
  }
  .Detailname_Island {
    font-size: 18px;
    color: white;
    background-color: #144d68;
    text-align: start;
    margin-left: 0%;
    margin-right: 0%;
    padding: 1%;
  }
  .Detailname_Island h1 {
    font-size: 18px;
    color: white;
    text-align: start;
  }

  .Listtext li {
    display: flex;
  }
  .Listtext p {
    margin: 2%;
  }

  .btnDay1 b,
  .btnDay2 b,
  .btnDay3 b {
    font-size: 16px;
    color: #144d68;
  }
  .Name_Overnight_trip h1 {
    font-size: 20px;
    padding-left: 20px;
  }
  .Accordion_Overnight img {
    width: 50px;
    height: 50px;
  }
  .Headname_Island h1 {
    font-size: 20px;
    padding-left: 20px;
  }
  .Accordion_Daytrip img {
    width: 50px;
    height: 50px;
  }
  .Headname_Island .col-sm-1 {
    width: 20%;
  }
  .Headname_Island .col-sm-11 {
    width: 80%;
  }
  .carousel_photo1 {
    padding-left: 5%;
    padding-right: 5%;
  }
  .carousel_photo {
    padding-left: 5%;
    padding-right: 5%;
  }
  .whattobring {
    color: white;
    margin-top: -10px;
  }
}
@media screen and (max-width: 540px) {
  .RECOMMEND1 {
    position: relative;
    margin: 0%;
    padding-left: 10%;
    padding-right: 10%;
  }
  .Detail_Daytrip .RECOMMEND1 .col {
    width: 50%;
  }
}
@media screen and (max-width: 350px) {
  .RECOMMEND1 {
    position: relative;
    margin: 0%;
    padding-left: 0;
    padding-right: 0;
  }
}

